@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body,input,button,select{font-family:"Montserrat", sans-serif}.button{padding:30px 15px;background:#ff0;color:#000;border:0}

